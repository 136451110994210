:root {
  --global-color-cyan: #cbfffd;
  --global-color-magenta: #b70097;
  --global-color-deep-purple: #36002d;
  --global-color-black: #140011;
  --global-color-white: #f9ffff;
  --global-color-trans-grey: rgba(54, 0, 45, 0.5);

  --background-color-body: var(--global-color-white);
  --background-color-filter: var(--global-color-cyan);
  --background-color-popout: var(--global-color-black);
  --color-body: var(--global-color-deep-purple);
  --color-link-hover: var(--global-color-magenta);
  --background-color-link-hover: var(--global-color-cyan);
  --background-color-button: var(--global-color-magenta);
  --background-color-button-2: var(--global-color-white);
  --color-button: var(--global-color-white);
  --color-button-2: var(--global-color-magenta);

  --padding-landscape: 1rem;
  --padding-portrait: 2rem;

  --font-size-body: 16px;

  --font-body: 'Figtree', sans-serif;
  --font-title: 'Judson', serif;
}

html,
body {
  min-height: 100vh;
}

header {
  flex-basis: 100%;
  background-color: var(--global-color-magenta);
  color: var(--global-color-white);
  padding: 0.25rem 0.5rem;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* background-image: linear-gradient(transparent, white);
  mix-blend-mode: saturation;
  padding: 50vh 1rem 1rem 1rem; */
  box-sizing: border-box;
  pointer-events: none;
  z-index: 9;
}

/* Safari */
@media screen and (min-width:0\0) {
  footer {
    background-image: linear-gradient(transparent, #fc1141) !important;
    mix-blend-mode: exclusion !important;
    padding: 30vh 1rem 1rem 1rem !important;
  }
}

/* Chrome */
@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) { footer {
  background-image: linear-gradient(transparent, #fc1141);
  mix-blend-mode: exclusion;
  padding: 30vh 1rem 1rem 1rem;
} }

/* Firefox */
@media screen and (min--moz-device-pixel-ratio:0) {
  footer {
    background-image: linear-gradient(transparent, white);
    mix-blend-mode: saturation;
    padding: 50vh 1rem 1rem 1rem;
  }
}

.App {flex-wrap: wrap}

@media screen and (min-width: 800px) {
  .App {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  #filterWrap {
    flex-basis: 20%;
  }
  #navWrap {
    flex-basis: 80%;
  }
  #filterWrap,
  #navWrap {
    /* height: calc(100vh - 1.219rem - 0.5rem); */
    height: 100vh;
    overflow-y: scroll;
  }
  #filter {
    flex-direction: column;
    text-align: right;
  }
  #nav {
    align-items: flex-start;
  }
}

#navWrap {
  position: relative;
  padding-bottom: 1rem;
  box-sizing: border-box;
}

body {
  font-family: var(--font-body);
  background-color: var(--background-color-body);
  font-size: var(--font-size-body);
}

#filterWrap {
  background-color: var(--background-color-filter);
}

#filter {
  overflow-x: scroll;
  flex-wrap: nowrap;
}

#nav {
  flex-wrap: wrap;
}

#nav,
#resume,
#error404,
#intro {
  padding: var(--padding-portrait);
}

#filter,
#nav {
  display: flex;
  width: 100%;
  margin: 0;
  column-gap: var(--padding-portrait);
  row-gap: var(--padding-portrait);
  box-sizing: border-box;
}

.filterItem button, button, #notice a {
  white-space: nowrap;
  border: none;
  border-radius: 3px;
  padding: 0 0.5rem;
  font-size: var(--font-size-body);
}

#filter {padding: var(--padding-landscape)}

@media screen and (min-width: 800px) {
  #nav,
  #resume,
  #error404,
  #intro {
    padding: var(--padding-landscape);
  }
  #filter,
  #nav {
    column-gap: var(--padding-landscape);
    row-gap: var(--padding-landscape);
  }
  #nav {
    flex-direction: row;
  }
  .filterItem button {
    white-space: normal;
  }
}

.gate,
.filterItem {
  display: inline-block;
  flex-basis: content;
  box-sizing: border-box;
  cursor: pointer;
}

.filterItem {
  list-style: none;
  cursor: default;
}

.gate {
  border: solid 1px var(--background-color-body);
  border-radius: 5px;
  overflow: hidden;
}

.pop.noFrame {
  background-color: var(--global-color-cyan);
}

#notice a {
  background-color: var(--background-color-button-2);
  color: var(--color-body);
  text-decoration: none;
}

#notice a:hover {
  background-color: var(--background-color-link-hover);
}

.pop.frame {
  flex-basis: 100%;
}

@media screen and (min-width: 800px) {
  .pop.frame {
    flex-basis: auto;
  }
}

.thumbnail {
  max-height: 240px;
  width: 100%;
  display: block;
  object-fit: cover;
}

@media screen and (min-width: 900px) {
  .thumbnail {
    height: 240px;
  }
}

#tellingEros > .thumbnail {
  object-position: center top;
}

.thumbnail:hover {
  filter: blur(10px);
}

body,
a {
  color: var(--color-body);
  transition: background-color 0.5s;
}

a:hover {
  color: var(--color-link-hover);
  background-color: var(--background-color-link-hover);
  transition: all 0.5s;
}

a:visited {
  color: var(--global-color-magenta);
}

button {
  font-weight: 600;
  cursor: pointer;
}

.filterOpen,
.closePop {
  background-color: var(--background-color-button);
  color: var(--color-button);
  border-color: var(--background-color-button);
}

.filterClosed,
.closePop:hover {
  background-color: var(--background-color-button-2);
  color: var(--color-button-2);
  border-color: var(--background-color-button-2);
}

img {
  border-radius: 5px;
}

#popWrap {
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--background-color-popout);
  color: var(--global-color-white);
  border: solid 1px var(--global-color-trans-grey);
  padding: 1rem;
  margin: var(--padding-landscape) var(--padding-portrait);
  box-shadow: 0.5rem 0.5rem 0 0 var(--global-color-trans-grey);
  max-width: calc(100vw - 2 * var(--padding-portrait));
  width: calc(100vw - 2 * var(--padding-portrait));
  cursor: default;
}

#popWrap:after {
  content: "";
  position: absolute;
  height: var(--padding-portrait);
  bottom: calc(-1 * var(--padding-portrait));
  width: 1px;
}

@media screen and (min-width: 800px) {
  #popWrap {
    margin: var(--padding-landscape) !important;
    width: calc(80vw - 2 * var(--padding-landscape)) !important;
  }
}

.closePop {
  display: block;
  position: absolute;
  right: 0;
  margin-right: 1rem;
}

#popWrap a {
  display: inline-block;
  margin: 0 1rem 1rem 0;
  color: var(--global-color-white);
}

#popWrap a:hover {
  background-color: var(--global-color-magenta);
}

.popGallery {
  column-gap: var(--padding-landscape);
  row-gap: var(--padding-landscape);
}

@media screen and (min-width: 800px) {
  .popGallery {
    columns: 2;
    column-gap: var(--padding-landscape);
  }
}

#popWrap img {
  width: 100%;
  height: auto;
  display: inline-block;
  box-sizing: border-box;
  border: solid 1px var(--global-color-white);
  margin-bottom: var(--padding-landscape);
}

.popDescription {
  columns: 1;
}

@media screen and (min-width: 800px) {
  .popDescription {
    columns: 2;
    column-gap: 1rem;
  }
}

p:first-child {
  margin-top: 0;
}

a, button, span {
  font-family: var(--font-body);
}

a, .pop span {font-weight: 600}
button {font-weight: 700}

h2 {
  font-family: var(--font-body);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}

h3 {
  font-family: var(--font-title);
  font-size: 1.5rem;
}

.tags,
.timespan {
  text-transform: uppercase;
  font-size: 0.8rem;
}

.tools {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 2rem;
}

#resume h3,
#resume .subheading {
  margin: 0
}

#resume h2 {margin-top: 0}

.timespan {margin: 0.5rem 0}

.single {margin-bottom: 1rem}

#resume section {margin-bottom: 3rem}
#resume section:last-child {margin-bottom: 0}

@media screen and (min-width: 800px) {
  .skills {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .skills h2 {flex-basis: 100%}
  .skills .single {
    flex-basis: auto;
    padding-right: 2rem;
  }
}

#intro {
  max-width: 50rem;
}

h1 {
  font-family: var(--font-title);
  font-size: 2.5rem;
  margin: 0;
  line-height: 1;
}

main {
  position: relative;
}

#notice {
  position: absolute;
  background-color: var(--global-color-black);
  color: var(--global-color-white);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 100;
  width: 70vw;
  height: 70vh;
  border-radius: 3px;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 7fr;
  text-align: center;
  overflow-y: scroll;
}

#notice > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#notice span {
  cursor: pointer;
  font-weight: bold;
  padding: 0.5em;
  justify-self: flex-start;
  align-self: flex-start;
  margin: auto;
}

#notice span:hover {
  background-color: var(--global-color-magenta);
  border-radius: 3px;
}